const cadfavoritos = () => import("@/views/modulos/suporte/cadfavoritos.vue");
const cadusuarios = () => import("@/views/modulos/suporte/cadusuarios.vue");
const cadtipo_doc_assinatura = () => import("@/views/modulos/suporte/cadtipo_doc_assinatura.vue");
const importardados = () => import("@/views/modulos/suporte/importar-dados.vue");
const portalalunoConfig = () => import("@/views/modulos/suporte/portalaluno-config.vue");
const importartabela = () => import("../importartabela.vue");

export default [

  {
    path: "/importartabela/:inc:mod:exc:codsis",
    name: "importartabela",
    props: true,
    component: importartabela,
  },

  {
    path: "/portalaluno-config/:inc:mod:exc:codsis",
    name: "portalalunoConfig",
    props: true,
    component: portalalunoConfig,
  },

  {
    path: "/cadfavoritos/:inc:mod:exc:codsis",
    name: "cadfavoritos",
    props: true,
    component: cadfavoritos,
  },
  {
    path: "/importardados/:inc:mod:exc:codsis",
    name: "importardados",
    props: true,
    component: importardados,
  },
  {
    path: "/cadusuarios/:inc:mod:exc:codsis",
    name: "cadusuarios",
    props: true,
    component: cadusuarios,
  },
  {
    path: "/cadtipo_doc_assinatura/:inc:mod:exc:codsis",
    name: "cadtipo_doc_assinatura",
    props: true,
    component: cadtipo_doc_assinatura,
  },
];
