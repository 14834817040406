import Vue from "vue";
import App from "./App.vue";

import JsonExcel from "vue-json-excel";
 
Vue.component("downloadExcel", JsonExcel);

import vuetify from "./plugins/vuetify";
//import vuesax from '@/plugins/vuesax.js';

import router  from "./router";
import store   from "./store/index";

//import tinymce from 'vue-tinymce-editor'
//Vue.component('tinymce', tinymce)

import vueVimeoPlayer from 'vue-vimeo-player'
Vue.use(vueVimeoPlayer)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

import "primevue/resources/primevue.min.css";
//import "primevue/resources/themes/rhea/theme.css";
import "primevue/resources/themes/mdc-light-indigo/theme.css";
//import "primevue/resources/themes/bootstrap4-light-blue/theme.css"

// import "primevue/resources/themes/saga-blue/theme.css";
// import "primevue/resources/themes/nova/theme.css";
//import "primevue/resources/themes/vela-green/theme.css";

import "primeicons/primeicons.css";
import "izitoast/dist/css/iziToast.css";
import VueIziToast from "vue-izitoast";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
//import ColumnGroup from 'primevue/columngroup';   // optional
//import Row from 'primevue/row';                   // optional

import Button from "primevue/button";
import Chart from "primevue/chart";
import InputText from "primevue/inputtext";
import Menubar from "primevue/menubar";
import InputSwitch from 'primevue/inputswitch';

import vSelectMenu from "v-selectmenu";

//import vmask from "./plugins/vmask";
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import FileUpload from "primevue/fileupload";

import VueNotification from "@kugatsu/vuenotification";

import SplitButton from "primevue/splitbutton";

;//import VueCurrencyInput from "vue-currency-input";
import {CurrencyDisplay, ValueScaling, useCurrencyInput } from "vue-currency-input";

import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
})

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Editor from 'primevue/editor';
import Tree from 'primevue/tree';

import Panel from 'primevue/panel';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputNumber from 'primevue/inputnumber'

import VueYoutube from 'vue-youtube'


Vue.use(VueYoutube)

//import CKEditor from '@ckeditor/ckeditor5-vue2';

//Vue.use( CKEditor );

/*  
import 'mdbvue/lib/mdbvue.css'

import * as mdbvue from 'mdbvue'
for (const component in mdbvue) {
  Vue.component(component, mdbvue[component])
}
*/

Vue.use(VueIziToast as any);

Vue.use(CurrencyDisplay as any);
Vue.use(ValueScaling as any)
Vue.use(useCurrencyInput as any)

Vue.use(VueNotification, {
  timer: 5,
});

Vue.use(vSelectMenu);
Vue.component("Tree", Tree);
Vue.component("Editor", Editor);
Vue.component("DataTable", DataTable);

Vue.component("Column", Column);
Vue.component("Button", Button);
Vue.component("Chart", Chart);
Vue.component("FileUpload", FileUpload);
Vue.component("SplitButton", SplitButton);
Vue.component("Accordion", Accordion);
Vue.component("AccordionTab", AccordionTab);
Vue.component("Menubar", Menubar);
Vue.component("TabView", TabView);
Vue.component("TabPanel", TabPanel);
Vue.component("Panel", Panel);
Vue.component("InputText", InputText);
Vue.component("InputNumber", InputNumber);
Vue.component("InputSwitch",InputSwitch)

Vue.config.productionTip = true;

new Vue({
 // vmask,
  vuetify,
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
