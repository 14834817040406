<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"
        ><span style="font-size: 12px">Aguarde</span></v-progress-circular
      >
    </v-overlay>
    <v-card>
      <sysbar
        titulo="Nova solicitação"
        @fechar="fecharnovasolicitacao"
      ></sysbar>
      <v-card-text>
        <v-row dense>
          <v-col cols="2" v-show="ambiente === 'normal'">
            <v-select
              :items="contratos"
              item-text="proc_descricao"
              item-value="m_id"
              label="Contratos"
              v-model="m_id"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              @change="clear_select"
              :items="servicos"
              item-text="serv_descricao"
              item-value="serv_id"
              label="Serviço"
              v-model="serv_id"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense style="margin-top: -20px">
          <v-col>
            <div v-html="orientacoes" />
          </v-col>
        </v-row>
        <div v-show="generico">
          <v-row dense style="margin-top: -20px">
            <v-col>
              <v-textarea
                counter
                maxlength="800"
                outlined
                dense
                hide-detaisl
                label="Descrição da solicitação"
                v-model="just"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense style="margin-top: -20px">
            <v-col>
              <Button label="Confirmar" @click="confirmarGenerico"></Button>
              <Button
                label="Cancelar"
                @click="generico = false"
                style="margin-left: 10px"
              ></Button>
            </v-col>
          </v-row>
        </div>
        <solicitacoes-eventos
          v-if="solevento"
          :aluno="aluno"
          :serv_id="serv_id"
          @cancelar="cancelarsolevento"
          @confirmar="confirmarsolevento"
        />
        <solicitacoes-dispensa
          v-if="dispensa"
          :aluno="aluno"
          :m_id="m_id"
          :serv_id="serv_id"
          @cancelar="cancelarsoldispensa"
          @confirmar="confirmarsoldispensa"
        />

        <solicitacoes-ativcompl
          v-if="ativcompl"
          :aluno="aluno"
          :serv_id="serv_id"
          :m_id="m_id"
          @cancelar="ativcompl = false"
          @confirmar="
            ativcompl = false;
            $emit('fecharnovasolicitacao', 'reload');
          "
        />
        <solicitacoes-comespecificacao
          v-if="comespecificacao"
          :aluno="aluno"
          :serv_id="serv_id"
          :m_id="m_id"
          @cancelar="comespecificacao = false"
          @confirmar="confirmarcomespecificacao"
        />

        <solicitacoes-conteudoprog
          :valor="servicos.filter((s) => s.serv_id === serv_id)[0].valor"
          v-if="conteudoprog"
          :aluno="aluno"
          :serv_id="serv_id"
          :m_id="m_id"
          @cancelar="cancelarconteudoprog"
          @confirmar="confirmarconteudoprog"
        />

        <justfaltas
          v-if="justfaltas"
          :disciplinas="disciplinas"
          :aluno="aluno"
          :serv_id="serv_id"
          :m_id="m_id"
          @confirmar="confirmar_justfaltas"
          @cancelar="cancelar_justfaltas"
        />

        <segchamada
          v-if="solSegChamada"
          :disciplinas="disciplinas"
          :aluno="aluno"
          :serv_id="serv_id"
          :m_id="m_id"
          @confirmar="confirmar_solsegchamada"
          @cancelar="cancelar_solsegchamada"
        />

        <solicitacoes-transfinterna
          v-if="transfinterna"
          :aluno="aluno"
          :serv_id="serv_id"
          :m_id="m_id"
          @confirmar="confirmar_transf"
          @cancelar="cancelar_transf"
        />

        <pre-matricula-academico
          v-if="prematricula"
          :aluno="aluno"
        ></pre-matricula-academico>

        <vendamaterial
          v-if="inc_vendamaterial"
          :listamaterial="listamaterialdidatico"
          :aluno="aluno"
          :serv_id="serv_id"
          :m_id="m_id"
          @confirmar_vendamaterial="confirmar_vendamaterial"
          @cancelar_vendamaterial="cancelar_vendamaterial"
        />

        <atividadeextraclasse
          v-if="inc_atividadeextraclasse"
          :listaatividadesextraclasse="listaatividadesextraclasse"
          :aluno="aluno"
          :serv_id="serv_id"
          :m_id="m_id"
          @confirmar="confirmar_ativ_extra_class"
          @cancelar="cancelar"
        />

        <Button
          v-show="
            !solSegChamada &&
            !transfinterna &&
            !inc_vendamaterial &&
            !justfaltas &&
            !solevento &&
            !conteudoprog &&
            !dispensa &&
            !comespecificacao &&
            !ativcompl &&
            !generico &&
            !inc_atividadeextraclasse
          "
          label="Prosseguir"
          style="width: 100%"
          @click="handlesol"
        ></Button>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import sysbar from "@/components/sysbar";
import http from "@/servicos/http.ts";
const justfaltas = () => import("@/components/justfaltas");
const vendamaterial = () => import("@/components/vendamaterial");
import segchamada from "@/components/segchamada";
import solicitacoesEventos from "@/components/solicitacoes-eventos";
import solicitacoesConteudoprog from "@/components/solicitacoes-conteudoprog";
import solicitacoesDispensa from "@/components/solicitacoes-dispensa";
import solicitacoesComespecificacao from "@/components/solicitacoes-comespecificacao";
import solicitacoesAtivcompl from "@/components/solicitacoes-ativcompl";
import solicitacoesTransfinterna from "@/components/solicitacoes-transfinterna";
import preMatriculaAcademico from "@/components/pre-matricula-academico";
import Config, { enviarEmail } from "@/config.ts";
import atividadeextraclasse from "./solicitacoes-atividade-extra-classe";
export default {
  name: "novasolicitacao",
  components: {
    atividadeextraclasse,
    sysbar,
    justfaltas,
    vendamaterial,
    solicitacoesEventos,
    solicitacoesConteudoprog,
    solicitacoesDispensa,
    solicitacoesComespecificacao,
    solicitacoesAtivcompl,
    solicitacoesTransfinterna,
    preMatriculaAcademico,
    segchamada,
  },

  props: ["aluno", "ambiente", "pessoa"],
  data() {
    return {
      listaatividadesextraclasse: "",
      inc_atividadeextraclasse: false,
      overlay: false,
      contratos: [],
      servicos: [],
      disciplinas: [],
      listamaterialdidatico: [],
      inc_vendamaterial: false,
      solSegChamada: false,
      justfaltas: false,
      solevento: false,
      conteudoprog: false,
      dispensa: false,
      comespecificacao: false,
      ativcompl: false,
      generico: false,
      transfinterna: false,
      prematricula: false,
      just: "",
      serv_id: "",
      m_id: "",
      nd_id: "",
      orientacoes: "",
      enviarEmail,
      tipo_licenca: "",
    };
  },
  created() {
    this.tipo_licenca = this.$store.state.contexto.FILIAL[0].e_tipo_licenca;

    if (this.pessoa === undefined) {
      this.pessoa = [{ ip_id: null }];
    }

    if (this.ambiente === undefined) {
      this.ambiente = "normal";
    }
    this.listacontratos().then(() => {
      this.listaservicos();
    });
  },
  methods: {
    confirmar_solsegchamada(dados) {
      this.solSegChamada = false;
      this.$emit("fecharnovasolicitacaocomimpressao", dados);
    },
    cancelar_solsegchamada() {
      this.solSegChamada = false;
    },
    confirmar_ativ_extra_class(dados) {
      this.inc_atividadeextraclasse = false;
      this.$emit("fecharnovasolicitacaocomimpressao", dados);
      this.$emit("fecharnovasolicitacao", "reload");
    },
    confirmarGenerico() {
      http
        .send("", {
          proc: "[FX jaguar solicitacoes]",
          0: "generico",
          1: this.serv_id,
          2: this.$store.state.contexto.BD[0].usr_id,
          3: null,
          4: null,
          5: null,
          6: this.just,
          7: this.m_id,
          8: this.aluno.cga,
        })
        .then((res) => {
          if (res.dados.data[0].msgemailaluno === null) {
            this.generico = false;
            this.$emit("fecharnovasolicitacao", "reload");
          } else {
            this.generico = false;
            this.$emit("fecharnovasolicitacao", "reload");
            var destino = res.dados.data[0].destinoresponsavel;
            var msg = res.dados.data[0].msgemailresponsavel;
            console.log("destino email", destino);
            enviarEmail(
              res.dados.data[0].destinoaluno,
              "Nova Solicitação",
              res.dados.data[0].msgemailaluno
            ).then((res) => {
              this.$toast.success("Email enviado para aluno");
              enviarEmail(destino, "Nova Solicitação", msg).then((res) => {
                this.$toast.success("Email enviado para responsável");
              });
            });
          }
        });
    },
    confirmarcomespecificacao() {
      this.comespecificacao = false;
      this.$emit("fecharnovasolicitacao", "reload");
    },
    confirmarsoldispensa() {
      this.dispensa = false;
      this.$emit("fecharnovasolicitacao", "reload");
    },
    confirmar_transf() {
      this.transfinterna = false;
      this.$emit("fecharnovasolicitacao", "reload");
    },
    confirmarsolevento() {
      this.solevento = false;
      this.$emit("fecharnovasolicitacao", "reload");
    },
    confirmarconteudoprog() {
      this.conteudoprog = false;
      this.$emit("fecharnovasolicitacao", "reload");
    },

    cancelarcomespecificacao() {
      this.comespecificacao = false;
    },
    cancelarsoldispensa() {
      this.dispensa = false;
    },
    cancelarconteudoprog() {
      this.conteudoprog = false;
    },
    cancelarsolevento() {
      this.solevento = false;
    },
    cancelar_transf() {
      this.transfinterna = false;
    },
    cancelar_vendamaterial() {
      this.inc_vendamaterial = false;
    },
    confirmar_vendamaterial(sol) {
      this.inc_vendamaterial = false;
      this.$emit("fecharnovasolicitacao", "reload");
    },
    cancelar_justfaltas() {
      this.justfaltas = false;
    },
    confirmar_justfaltas(sol) {
      this.justfaltas = false;
      this.$emit("fecharnovasolicitacao", "reload");
    },
    handlesol() {
      this.comespecificacao = false;
      this.justfaltas = false;
      this.inc_vendamaterial = false;
      this.solevento = false;
      this.conteudoprog = false;
      this.dispensa = false;
      this.generico = false;
      this.transfinterna = false;
      this.prematricula = false;
      this.solSegChamada = false;

      console.log(
        "servico",
        this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
      );

      if (
        this.servicos.filter((s) => s.serv_id === this.serv_id)[0].semfluxo ===
        "sim"
      ) {
        this.$toast.error(
          "Este serviço não tem fluxo associado. Solicitação não poderá prosseguir",
          "Info",
          { position: "center" }
        );
      } else {
        /****
         * Venda de material
         ****************************/
        if (
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
            .vendamaterial === "True"
        ) {
          http
            .send("", {
              proc: "[fx jaguar solicitacoes lista material didatico]",
              2: this.m_id,
            })
            .then((res) => {
              if (res.dados.data[0]) {
                this.listamaterialdidatico = res.dados.data;
                this.inc_vendamaterial = true;
                this.generico = false;
              } else {
                this.$toast.error(
                  "Não há material didático disponível",
                  "Info",
                  { position: "center" }
                );
              }
            });
        }

        /****
         * Atividade extra classe
         ****************************/
        console.log(
          "testando atividade extra classe",
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
          //.atividadeextraclasse
        );
        if (
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
            .atividadeextraclasse === "True"
        ) {
          http
            .send("", {
              proc: `[FX jaguar cadastros atividade_extra] 'list',null,null,null,'*',${this.aluno.e_id}`,
            })
            .then((res) => {
              if (res.dados.data[0]) {
                this.listaatividadesextraclasse = res.dados.data;
                this.inc_atividadeextraclasse = true;
                this.generico = false;
              } else {
                this.$toast.error(
                  "Não há atividade extra classe disponível",
                  "Info",
                  { position: "center" }
                );
              }
            });
        }

        /****
         * Transferencia interna
         ****************************/
        if (
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
            .prematricula === "True"
        ) {
          this.prematricula = true;
        }

        /****
         * Transferencia interna
         ****************************/
        if (
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
            .transfinternacurso === "True"
        ) {
          this.transfinterna = true;
        }

        /****
         * Eventos academicos
         ****************************/
        console.log(
          "evento",
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0].evento
        );
        if (
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0].evento ===
          "True"
        ) {
          this.solevento = true;
        }

        /****
         * Atividade complementar
         ****************************/
        if (
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
            .ativcompl === "True"
        ) {
          this.ativcompl = true;
        }

        /****
         * Com especificações
         ****************************/
        if (
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
            .comespecificacao === "True"
        ) {
          this.comespecificacao = true;
        }

        /****
         * Dispensa de disciplina / aproveitamento
         *******************************************/
        if (
          this.servicos
            .filter((s) => s.serv_id === this.serv_id)[0]
            .serv_aprovest.trim() === "True"
        ) {
          this.dispensa = true;
        }

        /****
         * Conteúdo programático
         ****************************/
        if (
          this.servicos
            .filter((s) => s.serv_id === this.serv_id)[0]
            .conteudoprog.trim() === "True"
        ) {
          this.conteudoprog = true;
        }

        /****
         * Justificativa de faltas
         ***************************/
        if (
          this.servicos
            .filter((s) => s.serv_id === this.serv_id)[0]
            .justfaltas.trim() === "True"
        ) {
          this.justfaltas = true;
          http
            .send("", {
              proc: "[fx jaguar listas]",
              1: "listadisciplinas",
              2: this.m_id,
            })
            .then((res) => {
              this.disciplinas = res.dados.data;
              this.nd_id = this.disciplinas[0].nd_id;
            });
        }

        /****
         * Segunda chamada
         ******************/
        console.log(
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0].segchamada
        );
        if (
          this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
            .segchamada === "True"
        ) {
          console.log("seg chamada ok");

          this.solSegChamada = true;
          this.generico = false;

          http
            .send("", {
              proc: "[fx jaguar listas]",
              1: "listadisciplinas",
              2: this.m_id,
            })
            .then((res) => {
              console.log("disciplinas");
              console.log(res.dados.data);
              this.disciplinas = res.dados.data;
              this.nd_id = this.disciplinas[0].nd_id;
            });
        }

        /****
         * Generico
         ****************************/

        if (
          !this.vendamaterial &&
          !this.transfinterna &&
          !this.justfaltas &&
          !this.solevento &&
          !this.conteudoprog &&
          !this.dispensa &&
          !this.comespecificacao &&
          !this.ativcompl &&
          !this.prematricula &&
          !this.solSegChamada
        ) {
          this.generico = true;
        }
      }
    },
    clear_select() {
      this.vendamaterial = false;
      this.justfaltas = false;
      this.solevento = false;
      this.conteudoprog = false;
      this.dispensa = false;
      this.comespecificacao = false;
      this.ativcompl = false;
      this.generico = false;
      this.orientacoes =
        "<b>Orientaçoes</b><br/>" +
        this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
          .serv_orientacoes;
    },
    listaservicos() {
      if (this.ambiente === "aluno") {
        http
          .send("", {
            proc: "[FX jaguar solicitacoes]",
            1: "listaservalunos",
            2: "",
            3: "0",
            4: "",
            5: "",
            6: "",
            7: "",
            8: "",
            9: "",
            10: this.aluno.cga,
          })
          .then((res) => {
            this.servicos = res.dados.data;
            this.serv_id = this.servicos[0].serv_id;
            this.orientacoes =
              "<b>Orientaçoes</b><br/>" +
              this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
                .serv_orientacoes;
          });
      } else {
        if (this.tipo_licenca === "ECLESIASTICO") {
          http
            .send("", {
              proc: `[fx jaguar solicitacoes - novo] 'listaeclesiastico',${this.e_id},'${this.aluno.cga}','${this.pessoa.ip_id}'`,
            })
            .then((res) => {
              this.servicos = res.dados.data;
              this.serv_id = this.servicos[0].serv_id;
              this.orientacoes =
                "<b>Orientaçoes</b><br/>" +
                this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
                  .serv_orientacoes;
            });
        } else {
          http
            .send("", {
              proc: "[FX jaguar solicitacoes]",
              1: "listaServComFiltro",
              2: "",
              3:
                this.$store.state.contexto.BD[0].usr_id === undefined
                  ? "0"
                  : this.$store.state.contexto.BD[0].usr_id,
              4: "",
              5: "",
              6: "",
              7: "",
              8: "",
              9: "",
              10: this.aluno.cga,
            })
            .then((res) => {
              this.servicos = res.dados.data;
              this.serv_id = this.servicos[0].serv_id;
              this.orientacoes =
                "<b>Orientaçoes</b><br/>" +
                this.servicos.filter((s) => s.serv_id === this.serv_id)[0]
                  .serv_orientacoes;
            });
        }
      }
    },
    async listacontratos() {
      this.overlay = true;
      await http
        .send("", {
          proc: "[fx jaguar listas] ",
          1: "listacontratosaluno",
          2: this.aluno.cga,
        })
        .then((res) => {
          this.overlay = false;
          this.contratos = res.dados.data;
          this.m_id = this.contratos[0].m_id;
        });
    },
    fecharnovasolicitacao() {
      this.$emit("fecharnovasolicitacao");
    },
  },
};
</script>

<style></style>
