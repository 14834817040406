const atendAlunoModAcademico     = () => import("@/views/modulos/academico/atendAlunoModAcademico.vue");
const cadtabsiga                 = () => import("@/views/modulos/academico/cadtabsiga.vue");
const cadtipo_ingresso           = () => import("@/views/modulos/academico/cadtipo_ingresso.vue");
const caddoc_x_tipoigresso       = () => import("@/views/modulos/academico/caddoc_x_tipoingresso.vue");
const cadpa                      = () => import("@/views/modulos/academico/cadpa.vue");
const cadeventosacad             = () => import("@/views/modulos/academico/cadeventosacad.vue");
const cadmaterial_didatico_grupo = () => import("@/views/modulos/academico/cadmaterial_didatico_grupo.vue");
const cadocorrencias_natureza    = () => import("@/views/modulos/academico/cadocorrencias_natureza.vue");
const cadcurriculo               = () => import("@/views/modulos/academico/cadcurriculo.vue");
const cadturmas                  = () => import("@/views/modulos/academico/cadturmas.vue");
const caddisciplinas_serie       = () => import("@/views/modulos/academico/caddisciplinas_serie.vue");
const caddisciplinas             = () => import("@/views/modulos/academico/caddisciplinas.vue");
const cadcursos                  = () => import("@/views/modulos/academico/cadcursos.vue");
const cadgrau_academico          = () => import("@/views/modulos/academico/cadgrau_academico.vue");
const cadservicos                = () => import("@/views/modulos/academico/cadservicos.vue");
const cadsituacao_aluno          = () => import("@/views/modulos/academico/cadsituacao_aluno.vue");
const cadlibturma                = () => import("@/views/modulos/academico/cadlibturma.vue");
const cadcalendario_academico    = () => import("@/views/modulos/academico/cadcalendario_academico.vue");
const cadprofessor               = () => import("@/views/modulos/academico/cadprofessor.vue");
const cadedital                  = () => import("@/views/modulos/academico/cadedital.vue");
const registrosdocentes          = () => import("@/views/modulos/academico/registrosdocentes.vue");
const cadplano_ensino_topicos    = () => import("@/views/modulos/academico/cadplano_ensino_topicos.vue");
const cadunidades                = () => import("@/views/modulos/academico/cadunidades.vue");
const cadmantenedora             = () => import("@/views/modulos/academico/cadmantenedora.vue");
const inventariomatricula        = () => import("@/views/modulos/academico/inventariomatricula.vue");
const cadprocacad                = () => import("@/views/modulos/academico/cadprocacad.vue");
const cadpredios                 = () => import("@/views/modulos/academico/cadpredios.vue");
//const cadbens                    = () => import("@/views/modulos/academico/cadbens.vue");
const cadturnos                  = () => import("@/views/modulos/academico/cadturnos.vue");
const cadatestados               = () => import("@/views/modulos/academico/cadatestados.vue");
const cadoutras_escolas          = () => import("@/views/modulos/academico/cadoutras_escolas.vue");
const cadescolas_origem          = () => import("@/views/modulos/academico/cadescolas_origem.vue");
const cadavisos                  = () => import("@/views/modulos/academico/cadavisos.vue");  
const cadavaliacao_pegagogica_natureza= () => import('@/views/modulos/academico/cadavaliacao_pegagogica_natureza')
const cadavaliacao_pegagogica_itens= () => import('@/views/modulos/academico/cadavaliacao_pegagogica_itens')
const cadtipo_ac= () => import('@/views/modulos/academico/cadtipo_ac')
const cadatividade_complementar = () => import('@/views/modulos/academico/cadatividade_complementar')
const cadformasaida = () => import('@/views/modulos/academico/cadformasaida')
const movimentacaoacademica = () => import('@/views/modulos/academico/movimentacaoacademica')
const selsolicitacoes = () => import('@/views/modulos/academico/selsolicitacoes')
const respondersolicitacao = () => import('@/views/modulos/academico/respondersolicitacao')
const dashboardAcademico = () => import('@/views/modulos/academico/dashboard-academico')
const cadouvidoriatipo = () => import('@/views/modulos/academico/cadouvidoriatipo')
const cadouvidoriastatus = () => import('@/views/modulos/academico/cadouvidoriastatus')
const cadouvidoriaassuntos = () => import('@/views/modulos/academico/cadouvidoriaassuntos')
const cadouvidoriasolucoes = () => import('@/views/modulos/academico/cadouvidoriasolucoes')
const manuthistorico = () => import('@/views/modulos/academico/manuthistorico')
const horarioporcurso = () => import('@/views/modulos/academico/horarioporcurso')
const cadies = () => import('@/views/modulos/academico/cadies')
const cadtipoavaliacaoprocessual = () => import('@/views/modulos/academico/cadtipoavaliacaoprocessual')
const portaldogestor = () => import('@/views/modulos/academico/portaldogestor')
const posicaodeaulasnasturmas = () => import('@/views/modulos/academico/posicaodeaulasnasturmas')
const responderouvidoria = () => import('@/views/modulos/academico/responderouvidoria')
const cadprocsel_concursos = () => import('@/views/modulos/academico/cadprocsel_concursos')
const cadcpa_questionario = () => import('@/views/modulos/academico/cadcpa_questionario')
const cadquest_pub_itens_respostas_config = () => import('@/views/modulos/academico/cadquest_pub_itens_respostas_config')
const cadformulaavaliacao = () => import('@/views/modulos/academico/cadformulaavaliacao')
const cadvacinas = () => import('@/views/modulos/academico/cadvacinas')

const cadavaliacaoPedagogicaNatureza = () => import('@/views/modulos/academico/cadavaliacao-pedagogica-natureza')
const cadtipoingresso = () => import('@/views/modulos/academico/cadtipoingresso')
const cadcampus = () => import('@/views/modulos/academico/cadcampus')
const integramoodle = () => import('@/views/modulos/academico/integramoodle')
const importarcadaluno = () => import('@/views/modulos/academico/importarcadaluno')

const cadinterv = () => import('@/views/modulos/academico/cadInterv')
const cadempestagio = () => import('@/views/modulos/academico/cadestagio_empresas')
const cadprofessordiploma = () => import('@/views/modulos/academico/cadprofessor-diploma')
const cadagendaonassuntos = () => import('@/views/modulos/academico/cadagendaon-assuntos')
const cadagendaondisponibilidade = () => import('@/views/modulos/academico/cadagendaon-disponibilidade')
const cadagendaon = () => import('@/views/modulos/academico/cadagendaon')
const listadiplomasregistrados = () => import("@/views/modulos/academico/listadiplomasregistrados")
const resumoalocprof = () => import("@/views/modulos/academico/resumoalocprof")
const cadcaptador = () => import("@/views/modulos/academico/cadcaptador")
const painelGestorAcademico = () => import("@/views/modulos/academico/painel-gestor-academico")
const cadeventosacadFluxoInternoPadrao = () => import("@/views/modulos/academico/cadeventosacad-fluxo-interno-padrao")
const cadeventosacadRecursosPadrao = () => import("@/views/modulos/academico/cadeventosacad-recursos-padrao")
const cadeventosacadPessoas = () => import("@/views/modulos/academico/cadeventosacad-pessoas")
const cadeventosacadCertificados = () => import("@/views/modulos/academico/cadeventosacad-certificados")
const cadeventosacadForm = () => import("@/views/modulos/academico/cadeventosacad-form")
const cadcalacadativgrupos = () => import("@/views/modulos/academico/cadcalacadativgrupos")
const tickets = () => import("@/views/modulos/academico/gerenciatikets")
const cadfichasocialmodelo = () => import("@/views/modulos/academico/cadfichasocialmodelo")
const cadcursositeview = () => import("@/views/modulos/academico/cadcursos-site-view")
const cadprocseletapas = () => import("@/views/modulos/academico/cadprocsel-etapas")
const cadcenso = () => import("@/views/modulos/academico/cadcenso")
const cadatividadeextra = () => import("@/views/modulos/academico/cadatividadeextra")

const cadportfolioCategoria = () => import("@/views/modulos/academico/cadportfolio-categoria")
const cadformsaplicar = () => import("@/views/modulos/academico/cadformsaplicar")
const cadmaterialdidatico = () => import("@/views/modulos/academico/cadmaterialdidatico")
const cadpastas = () => import("@/views/modulos/academico/cadpastas")
const cadtiporetornoatendimento = () => import("@/views/modulos/academico/cadtiporetornoatendimento")
const cadhorariosparam = () => import("@/views/modulos/academico/cadhorariosparam")

const cadportfoliotopicos = () => import("@/views/modulos/academico/cadportfoliotopicos")
const cadunidadesportfolio = () => import("@/views/modulos/academico/cadunidades-portfolio")


export default [
      {         
    path: "/cadunidadesportfolio/:inc:mod:exc:codsis",
    name: "cadunidadesportfolio",
    props: true,
    component:  cadunidadesportfolio,
  },
      {         
    path: "/cadportfoliotopicos/:inc:mod:exc:codsis",
    name: "cadportfoliotopicos",
    props: true,
    component:  cadportfoliotopicos,
  },
      {         
    path: "/cadhorariosparam/:inc:mod:exc:codsis",
    name: "cadhorariosparam",
    props: true,
    component:  cadhorariosparam,
  },
      {         
    path: "/cadtiporetornoatendimento/:inc:mod:exc:codsis",
    name: "cadtiporetornoatendimento",
    props: true,
    component:  cadtiporetornoatendimento,
  },
      {         
    path: "/cadmaterialdidatico/:inc:mod:exc:codsis",
    name: "cadmaterialdidatico",
    props: true,
    component: cadmaterialdidatico,
  },
      {         
    path: "/cadpastas/:inc:mod:exc:codsis",
    name: "cadpastas",
    props: true,
    component: cadpastas,
  },

      {         
    path: "/cadformsaplicar/:inc:mod:exc:codsis",
    name: "cadformsaplicar",
    props: true,
    component: cadformsaplicar,
  },

    {         
    path: "/cadportfolio-categoria/:inc:mod:exc:codsis",
    name: "cadportfolio-categoria",
    props: true,
    component: cadportfolioCategoria,
  },

    {         
    path: "/cadatividadeextra/:inc:mod:exc:codsis",
    name: "cadatividadeextra",
    props: true,
    component: cadatividadeextra,
  },

  {         
    path: "/cadcenso/:inc:mod:exc:codsis",
    name: "censo",
    props: true,
    component: cadcenso,
  },

  {         
    path: "/cadprocseletapas/:inc:mod:exc:codsis",
    name: "cadprocseletapas",
    props: true,
    component: cadprocseletapas,
  },

  {         
    path: "/cadcursositeview",
    name: "cadcursositeview",
    props: true,
    component: cadcursositeview,
  },

  {         
    path: "/tickets/:inc:mod:exc:codsis",
    name: "tickets",
    props: true,
    component: tickets,
  },

    {         
    path: "/cadfichasocialmodelo/:inc:mod:exc:codsis",
    name: "cadfichasocialmodelo",
    props: true,
    component: cadfichasocialmodelo,
  },

  {         
    path: "/cadcalacadativgrupos/:inc:mod:exc:codsis",
    name: "cadcalacadativgrupos",
    props: true,
    component: cadcalacadativgrupos,
    
  },
  {         
    path: "/cadeventosacad-form/:inc:mod:exc:codsis",
    name: "cadeventosacad-form",
    props: true,
    component: cadeventosacadForm,
    
  },
  {         
    path: "/cadeventosacad-certificados/:inc:mod:exc:codsis",
    name: "cadeventosacad-certificados",
    props: true,
    component: cadeventosacadCertificados,
    
  },
  {         
    path: "/cadeventosacad-pessoas/:inc:mod:exc:codsis",
    name: "cadeventosacad-pessoas",
    props: true,
    component: cadeventosacadPessoas,
    
  },
  {
    path: "/cadeventosacad-recursos-padrao/:inc:mod:exc:codsis",
    name: "cadeventosacad-recursos-padrao",
    props: true,
    component: cadeventosacadRecursosPadrao,
  },
  {
    path: "/cadeventosacad-fluxo-interno-padrao/:inc:mod:exc:codsis",
    name: "cadeventosacad-fluxo-interno-padrao",
    props: true,
    component: cadeventosacadFluxoInternoPadrao,
  },
    {
    path: "/painel-gestor-academico/:inc:mod:exc:codsis",
    name: "painel-gestor-academico",
    props: true,
    component: painelGestorAcademico,
  },

  {
    path: "/cadcaptador/:inc:mod:exc:codsis",
    name: "cadcaptador",
    props: true,
    component: cadcaptador,
  },
  {
    path: "/resumoalocprof/:inc:mod:exc:codsis",
    name: "resumoalocprof",
    props: true,
    component: resumoalocprof,
  },
  {
    path: "/listadiplomasregistrados/:inc:mod:exc:codsis",
    name: "listadiplomasregistrados",
    props: true,
    component: listadiplomasregistrados,
  },
  {
    path: "/cadagendaon/:inc:mod:exc:codsis",
    name: "cadagendaon",
    props: true,
    component: cadagendaon,
  },
  {
    path: "/cadagendaondisponibilidade/:inc:mod:exc:codsis",
    name: "cadagendaondisponibilidade",
    props: true,
    component: cadagendaondisponibilidade,
  },
  {
    path: "/cadagendaonassuntos/:inc:mod:exc:codsis",
    name: "cadagendaonassuntos",
    props: true,
    component: cadagendaonassuntos,
  },
  {
    path: "/cadprofessordiploma/:inc:mod:exc:codsis",
    name: "cadprofessordiploma",
    props: true,
    component: cadprofessordiploma,
  },
  {
    path: "/cadempestagio/:inc:mod:exc:codsis",
    name: "cadempestagio",
    props: true,
    component: cadempestagio,
  },
    {
    path: "/cadformulaavaliacao/:inc:mod:exc:codsis",
    name: "cadformulaavaliacao",
    props: true,
    component: cadformulaavaliacao,
  },

    {
    path: "/cadinterv/:inc:mod:exc:codsis",
    name: "cadinterv",
    props: true,
    component: cadinterv,
  },

  {
    path: "/importarcadaluno/:inc:mod:exc:codsis",
    name: "importarcadaluno",
    props: true,
    component: importarcadaluno,
  },
  {
    path: "/integramoodle/:inc:mod:exc:codsis",
    name: "integramoodle",
    props: true,
    component: integramoodle,
  },
  {
    path: "/cadcampus/:inc:mod:exc:codsis",
    name: "cadcampus",
    props: true,
    component: cadcampus,
  },

  {
    path: "/cadtipoingresso/:inc:mod:exc:codsis",
    name: "cadtipoingresso",
    props: true,
    component: cadtipoingresso,
  },

    {
    path: "/cadavaliacao-pedagogica-natureza/:inc:mod:exc:codsis",
    name: "cadavaliacao-pedagogica-natureza",
    props: true,
    component: cadavaliacaoPedagogicaNatureza,
  },

  {
    path: "/cadquest_pub_itens_respostas_config/:inc:mod:exc:codsis",
    name: "cadquest_pub_itens_respostas_config",
    props: true,
    component: cadquest_pub_itens_respostas_config,
  },

  {
    path: "/cadvacinas/:inc:mod:exc:codsis",
    name: "cadvacinas",
    props: true,
    component: cadvacinas,
  },

  {
    path: "/cadcpa_questionario/:inc:mod:exc:codsis",
    name: "cadcpa_questionario",
    props: true,
    component: cadcpa_questionario,
  },
  {
    path: "/cadprocsel_concursos/:inc:mod:exc:codsis",
    name: "cadprocsel_concursos",
    props: true,
    component: cadprocsel_concursos,
  },

  {
    path: "/cadtipoavaliacaoprocessual/:inc:mod:exc:codsis",
    name: "cadtipoavaliacaoprocessual",
    props: true,
    component: cadtipoavaliacaoprocessual,
  },

  {
    path: "/responderouvidoria/:inc:mod:exc:codsis",
    name: "responderouvidoria",
    props: true,
    component: responderouvidoria,
  },

  {
    path: "/portaldogestor/:inc:mod:exc:codsis",
    name: "portaldogestor",
    props: true,
    component: portaldogestor,
  },

  {
    path: "/posicaodeaulasnasturmas/:inc:mod:exc:codsis",
    name: "posicaodeaulasnasturmas",
    props: true,
    component: posicaodeaulasnasturmas,
  },

  {
    path: "/manuthistorico/:inc:mod:exc:codsis",
    name: "manuthistorico",
    props: true,
    component: manuthistorico,
  },
  {
    path: "/cadies/:inc:mod:exc:codsis",
    name: "cadies",
    props: true,
    component: cadies,
  },
  {
    path: "/horarioporcurso/:inc:mod:exc:codsis",
    name: "horarioporcurso",
    props: true,
    component: horarioporcurso,
  },
  {
    path: "/cadouvidoriasolucoes/:inc:mod:exc:codsis",
    name: "cadouvidoriasolucoes",
    props: true,
    component: cadouvidoriasolucoes,
  },
  {
    path: "/cadouvidoriaassuntos/:inc:mod:exc:codsis",
    name: "cadouvidoriaassuntos",
    props: true,
    component: cadouvidoriaassuntos,
  },
  {
    path: "/cadouvidoriastatus/:inc:mod:exc:codsis",
    name: "cadouvidoriastatus",
    props: true,
    component: cadouvidoriastatus,
  },
  {
    path: "/cadouvidoriatipo/:inc:mod:exc:codsis",
    name: "cadouvidoriatipo",
    props: true,
    component: cadouvidoriatipo,
  },
  {
    path: "/dashboard-academico/:inc:mod:exc:codsis",
    name: "dashboard-academico",
    props: true,
    component: dashboardAcademico,
  },
  {
    path: "/respondersolicitacao/:inc:mod:exc:codsis",
    name: "respondersolicitacao",
    props: true,
    component: respondersolicitacao,
  },
  {
    path: "/selsolicitacoes/:inc:mod:exc:codsis",
    name: "selsolicitacoes",
    props: true,
    component: selsolicitacoes,
  },
  {
    path: "/movimentacaoacademica/:inc:mod:exc:codsis",
    name: "movimentacaoacademica",
    props: true,
    component: movimentacaoacademica,
  },
  {
    path: "/cadatividade_complementar/:inc:mod:exc:codsis",
    name: "cadatividade_complementar",
    props: true,
    component: cadatividade_complementar,
  },
  {
    path: "/cadformasaida/:inc:mod:exc:codsis",
    name: "cadformasaida",
    props: true,
    component: cadformasaida,
  },
  {
    path: "/cadtipo_ac/:inc:mod:exc:codsis",
    name: "cadtipo_ac",
    props: true,
    component: cadtipo_ac,
  },
  {
    path: "/cadavaliacao_pegagogica_itens/:inc:mod:exc:codsis",
    name: "cadavaliacao_pegagogica_itens",
    props: true,
    component: cadavaliacao_pegagogica_itens,
  },
  {
    path: "/cadavaliacao_pegagogica_natureza/:inc:mod:exc:codsis",
    name: "cadavaliacao_pegagogica_natureza",
    props: true,
    component: cadavaliacao_pegagogica_natureza,
  },
  {
    path: "/cadavisos/:inc:mod:exc:codsis",
    name: "cadavisos",
    props: true,
    component: cadavisos,
  },
  {
    path: "/cadescolas_origem/:inc:mod:exc:codsis",
    name: "cadescolas_origem",
    props: true,
    component: cadescolas_origem,
  },
  {
    path: "/cadoutras_escolas/:inc:mod:exc:codsis",
    name: "cadoutras_escolas",
    props: true,
    component: cadoutras_escolas,
  },
  {
    path: "/cadatestados/:inc:mod:exc:codsis",
    name: "cadatestados",
    props: true,
    component: cadatestados,
  },
  {
    path: "/cadturnos/:inc:mod:exc:codsis",
    name: "cadturnos",
    props: true,
    component: cadturnos,
  },
  /*
  {
    path: "/cadbens/:inc:mod:exc:codsis",
    name: "cadbens",
    props: true,
    component: cadbens,
  }, */
  {
    path: "/cadpredios/:inc:mod:exc:codsis",
    name: "cadpredios",
    props: true,
    component: cadpredios,
  },
  {
    path: "/cadprocacad/:inc:mod:exc:codsis",
    name: "cadprocacad",
    props: true,
    component: cadprocacad,
  },
  {
    path: "/inventariomatricula/:inc:mod:exc:codsis",
    name: "inventariomatricula",
    props: true,
    component: inventariomatricula,
  },
  {
    path: "/cadmantenedora/:inc:mod:exc:codsis",
    name: "cadmantenedora",
    props: true,
    component: cadmantenedora,
  },
  {
    path: "/cadunidades/:inc:mod:exc:codsis",
    name: "cadunidades",
    props: true,
    component: cadunidades,
  },
  {
    path: "/atendAlunoModAcademico/:inc:mod:exc:codsis",
    name: "atendalunomodacademico",
    props: true,
    component: atendAlunoModAcademico,
  }/*,
  {
    path: "/cadtabsiga/:inc:mod:exc:codsis",
    name: "cadtabsiga",
    props: true,
    component: cadtabsiga,
  }*/,
  {
    path: "/cadtipo_ingresso/:inc:mod:exc:codsis",
    name: "cadtipo_ingresso",
    props: true,
    component: cadtipo_ingresso,
  },
  {
    path: "/caddoc_x_tipoigresso/:inc:mod:exc:codsis",
    name: "caddoc_x_tipoigresso",
    props: true,
    component: caddoc_x_tipoigresso,
  },
  {
    path: "/cadpa/:inc:mod:exc:codsis",
    name: "cadpa",
    props: true,
    component: cadpa,
  },
  {
    path: "/cadmaterial_didatico_grupo/:inc:mod:exc:codsis",
    name: "cadmaterial_didatico_grupo",
    props: true,
    component: cadmaterial_didatico_grupo,
  },
  {
    path: "/cadeventosacad/:inc:mod:exc:codsis",
    name: "cadeventosacad",
    props: true,
    component: cadeventosacad,
  },
    {
    path: "/cadocorrencias_natureza/:inc:mod:exc:codsis",
    name: "cadocorrenciasnatureza",
    props: true,
    component: cadocorrencias_natureza,
  },
    {
    path: "/cadcurriculo/:inc:mod:exc:codsis",
    name: "cadcurriculo",
    props: true,
    component: cadcurriculo,
  },
  {
    path: "/cadturmas/:inc:mod:exc:codsis",
    name: "cadturmas",
    props: true,
    component: cadturmas,
  },
  {
    path: "/caddisciplinas_serie/:inc:mod:exc:codsis",
    name: "caddisciplinas_serie",
    props: true,
    component: caddisciplinas_serie,
  },
  {
    path: "/caddisciplinas/:inc:mod:exc:codsis",
    name: "caddisciplinas",
    props: true,
    component: caddisciplinas,
  },                
  {
    path: "/cadcursos/:inc:mod:exc:codsis",
    name: "cadcursos",
    props: true,
    component: cadcursos,
  },                
  {
    path: "/cadgrau_academico/:inc:mod:exc:codsis",
    name: "cadgrau_academico",
    props: true,
    component: cadgrau_academico,
  },                
    {
    path: "/cadservicos/:inc:mod:exc:codsis",
    name: "cadservicos",
    props: true,
    component: cadservicos,
  },                
    {
    path: "/cadsituacao_aluno/:inc:mod:exc:codsis",
    name: "cadsituacao_aluno",
    props: true,
    component: cadsituacao_aluno,
  },                
    {
    path: "/cadlibturma/:inc:mod:exc:codsis",
    name: "cadlibturma",
    props: true,
    component: cadlibturma,
  },                
    {
    path: "/cadcalendario_academico/:inc:mod:exc:codsis",
    name: "cadcalendario_academico",
    props: true,
    component: cadcalendario_academico,
  },                
    {
    path: "/cadprofessor/:inc:mod:exc:codsis",
    name: "cadprofessor",
    props: true,
    component: cadprofessor,
  },                
    {
    path: "/cadedital/:inc:mod:exc:codsis",
    name: "cadedital",
    props: true,
    component: cadedital,
  },                
    {
    path: "/registrosdocentes/:inc:mod:exc:codsis",
    name: "registrosdocentes",
    props: true,
    component: registrosdocentes,
  },                
    {
    path: "/cadplano_ensino_topicos/:inc:mod:exc:codsis",
    name: "cadplano_ensino_topicos",
    props: true,
    component: cadplano_ensino_topicos,
  },                
    
];
