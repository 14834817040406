<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"
        ><span style="font-size: 12px">Aguarde</span></v-progress-circular
      >
    </v-overlay>

    <v-row v-if="!selunidade">
      <v-col cols="12">
        <div style="height: 85vh" class="centralizar">
          <div>
            <v-card width="400">
              <v-card-title style="background-color: #0d47a1; color: white"
                >Contexto/Empresa</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <v-row dense>
                  <v-col colas="12">
                    <v-select
                      dense
                      outlined
                      style="font-size: 14px"
                      :items="dados"
                      item-text="bco_empresa"
                      item-value="bco_nome"
                      label="Selecione"
                      v-model="banco"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <Button
                  label="Confirmar"
                  class="p-button-raissed"
                  style="width: 100%; margin-top: -15px"
                  @click="confirmar_banco(null)"
                ></Button>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="show_dashboard" fullscreen persistent>
      <v-card>
        <sysbar
          titulo="Responder solicitações"
          @fechar="fechar_responder_solicitacoes"
        />
        <v-card-text>
          <respondersolicitacao
            v-if="show_dashboard"
            @fechar="fechar_responder_solicitacoes"
          ></respondersolicitacao>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show_msg_sol" width="300" persistent>
      <v-card>
        <v-card-title> Atençao </v-card-title>
        <v-card-text>
          Existem requerimentos que precisam da sua atençao!
        </v-card-text>
        <v-card-action>
          <div style="text-align: center">
            <Button
              class="p-button-raised"
              style="margin: 5px; width: 95%"
              label="Ok"
              @click="btnClick_dashboard"
            />
          </div>
        </v-card-action>
      </v-card>
    </v-dialog>

    <v-row v-if="selunidade">
      <v-col cols="12">
        <div style="height: 85vh" class="centralizar">
          <div>
            <v-card width="400">
              <v-card-title style="background-color: #0d47a1; color: white"
                >Unidade de ensino</v-card-title
              >
              <v-card-subtitle style="background-color: #0d47a1; color: white"
                >Selecione a unidade de ensino do contexto
                <b>{{
                  $store.state.contexto.BD[0].bco_empresa
                }}</b></v-card-subtitle
              >
              <v-divider></v-divider>
              <v-card-text>
                <v-row dense>
                  <v-col colas="12">
                    <v-select
                      dense
                      outlined
                      style="font-size: 14px"
                      :items="filiais"
                      item-text="e_nomefantasia"
                      item-value="e_id"
                      label="Selecione"
                      v-model="e_id"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <Button
                  label="Confirmar"
                  class="p-button-raissed"
                  style="width: 100%; margin-top: -15px"
                  @click="confirmar(null)"
                ></Button>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import http from "@/servicos/http.ts";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import Config, { baseapisocket, formatData, listaFiliais } from "@/config.ts";
import respondersolicitacao from "@/views/modulos/academico/respondersolicitacao";
import sysbar from "@/components/sysbar";

export default {
  name: "selbancodados",
  components: { respondersolicitacao, sysbar },
  props: ["perfil", "funcao", "bco_id"],
  data() {
    return {
      dados: this.$store.state.contexto.BDLista,
      banco: this.$store.state.contexto.BDLista[0].bco_nome,
      dadosmenu: null,
      paginas: null,
      menu: null,
      submenu: null,
      menu_geral: [],
      overlay: false,
      selunidade: false,
      dashboard: "",
      relatorios: "",
      ws_client: "",
      formatData,
      foto_usuario: "",
      e_id: "",
      filiais: "",
      show_msg_sol: false,
      registros_periodos: "",
      p_id: "",
      show_dashboard: false,
      show_msg_fec_caixa: false,
    };
  },
  created() {
    this.$store.commit("setMenu", null);

    if (
      this.$store.state.contexto.usuario.perfil.perfil.perfil === "Professor"
    ) {
      this.dashboard = "registrosdocentes";
    }

    if (
      this.$store.state.contexto.usuario.perfil.perfil.perfil === "Colaborador"
    ) {
      this.dashboard = "dashboard";
      console.log("indo para menu principal");
    }

    if (this.funcao === undefined) {
      this.initapi();
    } else {
      this.confirmar(this.bco_id);
    }

    if (this.dados[1] === undefined) {
      this.confirmar_banco(null).then(() => {
        this.selunidade = false;
      });
    }
  },
  methods: {
    fechar_responder_solicitacoes() {
      this.show_dashboard = false;
      this.show_msg_sol = false;
      this.$router.push({ name: `registrosdocentes` });
    },
    btnClick_dashboard() {
      if (
        this.$store.state.contexto.usuario.perfil.perfil.perfil === "Professor"
      ) {
        this.show_dashboard = true;
      } else {
        this.show_msg_sol = false;
        this.show_msg_fec_caixa = false;
        this.$router.push({ name: `${this.dashboard}` });
      }
    },

    _checkNotifications() {
      if (typeof Notification === "function") {
        if (Notification.permission !== "granted") {
          Notification.requestPermission((permission) => {
            if (permission === "granted") {
            }
          });
        }
      }
    },
    _notification(origem, data) {
      if (Notification.permission === "granted") {
        var n = new Notification(origem, { icon: null, body: data });
        setTimeout(() => {
          if (n) n.close();
        }, 5000);
      }
    },
    async initapi() {
      var origem = "";
      if (
        document.URL.includes("10.1.1.104") ||
        document.URL.includes("localhost")
      ) {
        origem = "local";
      } else {
        origem = "remoto";
      }
      await http
        .send("", {
          proc: `[init params] '${origem}'`,
          banco: "manager_jaguar",
        })
        .then((response) => {
          if (response.dados.data[0]) {
            this.$store.commit("setAPI", response.dados.data[0].api);
            this.$store.commit("setAPIREL", response.dados.data[0].apirel);
            this.$store.commit(
              "setAPIUPLOAD",
              response.dados.data[0].apiupload
            );
            this.$store.commit("setAPIDOCS", response.dados.data[0].apidocs);
            this.$store.commit(
              "setAPIRELPDF",
              response.dados.data[0].apirelpdf
            );
            this.$store.commit(
              "setAPIRELXLS",
              response.dados.data[0].apirelxls
            );
          }
        });
    },
    showHtml_(f, t, r, fs_http) {
      var comp = f; //.split("/");
      this.$router.push({ name: `/${f.split(" ").join("")}` });
      this.$store.commit("setTitulo", t);
      this.$store.commit("setRelatorio", r);
    },
    showHtml(f, t, r, fs_http) {
      console.log("html-f", f);
      console.log("html-t", t);
      console.log("html-r", r);

      if (t.includes("Virtual")) {
        window.open(fs_http, "_blank");
      } else {
        this.$router.push(`/${f.split(" ").join("")}`);

        var comp = f.split(" ").join("");
        comp = comp.split("/");
        this.$store.commit("setTitulo", t);
        this.$store.commit("setRelatorio", r);
      }
    },
    /*
    _onConnected(frame) {
      var url = this.ws_client.ws._transport.url;
      var sessionId = "";

      var base = baseapisocket;
      base = base.replace("http", "ws");
      url = url
        .split(`${base}/ws/`)
        .join("")
        .replace("/websocket", "")
        .replace(/^[0-9]+\//, "");
      this.$store.commit("setws_sessionid", url);
      sessionId = url;
      var dados = {
        sessao: sessionId,
        perfil: this.$store.state.contexto.usuario.perfil.perfil.perfil,
        banco: this.$store.state.contexto.BD[0].bco_nome,
        usr_nome: this.$store.state.contexto.BD[0].usr_nome,
      };

      this.$store.commit("setws_client", this.ws_client);
      this.ws_client.subscribe("/topic/response", this.onMessageReceive);
      this.ws_client.send("/app/join", JSON.stringify(dados), {});
    },

    onMessageReceive(payload) {
      var dados = JSON.parse(payload.body);
      if (this.$store.state.contexto.BD[0].usr_nome === dados.destino) {
        this.$toast.info("Mensagem nova");
        console.log("notificando");
        console.log(dados);
        this.notification(dados.origem, dados.msg);
        console.log("notificado");
        //this.$toast.success("Chegou mensagem de " + dados.origem + " " + dados.msg + " em " + formatData(dados.date));
      }

      const message = JSON.parse(payload.body);

      if (message.users) {
        this.$store.commit(
          "setUsuariosConectados",
          Object.values(
            message.users.filter(
              (u) => u.banco === this.$store.state.contexto.BD[0].bco_nome
            )
          )
        );
      }
    },
*/
    carrega_foto_perfil() {
      this.foto_usuario = `${
        Config.baseApiUrlUpload
      }/${this.$store.state.contexto.BD[0].bco_pasta.trim()}/usuarios/perfil/${
        this.$store.state.contexto.BD[0].usr_id
      }/${this.$store.state.usr_foto_perfil}`;
      this.foto_usuario === undefined ? "" : this.foto_usuario;
    },

    onError() {},
    async confirmar_banco(_bco) {
      var bco;

      //this.checkNotifications();

      if (_bco === null) {
        bco = this.$store.state.contexto.BDLista.filter((b) => {
          return b.bco_nome === this.banco;
        });
        this.$store.commit("setBD", bco);
      }

      const perfil = this.$store.state.contexto.usuario.perfil.perfil.perfil;

      console.log("chklogin");
      http
        .send("", {
          proc: `[FX jaguar Basico chkLogin] '${this.$store.state.contexto.BD[0].usr_login}','consolidada',${this.$store.state.contexto.BD[0].usr_id}`,
        })
        .then((res) => {
          this.$store.commit(
            "setFotoPerfil",
            res.dados.data[0].usr_foto_perfil
          );
        });

      if (perfil === "Professor") {
        await http
          .send("", {
            proc: "[FX jaguar registro docentes] ",
            0: "getprof",
            1: this.$store.state.contexto.BD[0].usr_id,
          })
          .then((res) => {
            const f = res.dados.data[0];
            this.$store.state.contexto.FILIAL[0].e_nomefantasia =
              f.e_nomefantasia;
            this.$store.state.contexto.FILIAL[0].smtp_host = f.smtp_host;
            this.$store.state.contexto.FILIAL[0].smtp_porta = f.smtp_porta;
            this.$store.state.contexto.FILIAL[0].user_name = f.user_name;
            this.$store.state.contexto.FILIAL[0].user_password =
              f.user_password;

            this.p_id = res.dados.data[0].p_id;
            this.$store.commit("setPerfil", {
              perfil: perfil,
              p_id: res.dados.data[0].p_id,
              func_nome: res.dados.data[0].func_nome,
              func_id: res.dados.data[0].func_id,
            });

            this.$store.state.contexto.BD[0].bco_pasta =
              res.dados.data[0].e_pasta;
          });
      }

      if (perfil === "Professor") {
        this.e_id = "0";
        this.filiais = [];
        this.confirmar(null);
      } else {
        listaFiliais().then((res) => {
          this.filiais = res.dados.data;
          this.e_id = res.dados.data[0].e_id;
          if (this.filiais[1] !== undefined) {
            this.selunidade = true;
          } else {
            this.confirmar(this.bco_id, perfil);
          }
        });
      }
    },

    async confirmar(_bco, perfil) {
      if (
        this.$store.state.contexto.usuario.perfil.perfil.perfil ===
        "Colaborador"
      ) {
        this.$store.commit(
          "setFilial",
          this.filiais.filter((f) => f.e_id === this.e_id)
        );
      }

      var bco = this.$store.state.contexto.BD;
      try {
        this.$store.state.contexto.BD[0].bco_pasta = this.filiais.filter(
          (f) => f.e_id === this.e_id
        )[0].e_pasta;
      } catch (e) {}
      /*      

      this.checkNotifications();

      console.log(_bco);
      if (_bco === null) {
        bco = this.$store.state.contexto.BDLista.filter((b) => {
          return b.bco_nome === this.banco;
        });
        this.$store.commit("setBD", bco);
      }

      const perfil = this.$store.state.contexto.usuario.perfil.perfil.perfil;

      console.log("consolidado");
      http
        .send("", {
          proc: `[FX jaguar Basico chkLogin] '${this.$store.state.contexto.BD[0].usr_login}','consolidada',${this.$store.state.contexto.BD[0].usr_id}`,
        })
        .then((res) => {
          console.log("valiadndo usuario");
          console.log(this.$store.state.contexto.usuario);
          console.log(res.dados);
          this.$store.commit("setFotoPerfil", res.dados.data[0].usr_foto_perfil);
          console.log("setando foto perfil");
          console.log(res.dados.data[0].usr_foto_perfil);

          // https://www.youtube.com/watch?v=O3lpblOUGck
          var sockJS = new SockJS(`${baseapisocket}/ws`);
          this.ws_client = Stomp.over(sockJS);

          this.ws_client.connect({}, this.onConnected, this.onError);
        });

      if (perfil === "Professor") {
        await http.send("", { proc: "[FX jaguar registro docentes] ", 0: "getprof", 1: this.$store.state.contexto.BD[0].usr_id }).then((res) => {
          this.$store.commit("setPerfil", {
            perfil: perfil,
            p_id: res.dados.data[0].p_id,
            func_nome: res.dados.data[0].func_nome,
            func_id: res.dados.data[0].func_id,
          });
        });
      }
*/
      await http
        .send("", {
          proc: "[FX jaguar cadastros favoritos]",
          1: "list",
          2: "0",
          3: "0",
          4: "0",
          5: "0",
          6: bco[0].usr_id,
        })
        .then((res) => {
          var fav = [
            {
              titulo: "Relatórios",
              icon: "mdi-note-text",
              funcao: () => this.showHtml("relatorios", "Relatório", ""),
            },
            {
              titulo: "Tutoriais",
              icon: "mdi-projector",
              funcao: () => this.showHtml("treinamentos", "Tutoriais", ""),
            },
          ];
          for (let index = 0; index < res.dados.data.length; index++) {
            console.log(
              "res.dados.data[index].fs_vue",
              res.dados.data[index].fs_vue
            );
            fav.push({
              titulo: res.dados.data[index].f_label
                ? res.dados.data[index].f_label
                : res.dados.data[index].fs_titulo,
              icon: res.dados.data[index].icon,
              funcao: () =>
                this.showHtml(
                  res.dados.data[index].fs_vue.split("naonaonao").join("111"),
                  res.dados.data[index].f_label
                    ? res.dados.data[index].f_label
                    : res.dados.data[index].fs_titulo,
                  res.dados.data[index].fs_relatorio,
                  res.dados.data[index].fs_http
                ),
            });
          }
          this.$store.commit("setFavoritos", fav);
          this.overlay = true;
          http
            .send("", {
              proc: "[FX jaguar Basico monta menu - js]",
              usr: bco[0].usr_id,
            })
            .then((res) => {
              // console.log("opcoes do menu");
              // console.log(res.dados);
              this.dadosmenu = res.dados.data;
              var itens = [];
              var relatorios = [];

              var m = "";
              try {
                for (let index = 0; index < this.dadosmenu.length; index++) {
                  m = this.dadosmenu[index];
                  if (
                    (m.fs_pagina === "Relatórios" ||
                      m.fs_nivel === "Relatórios") &&
                    m.fs_relatorio !== ""
                  ) {
                    if (!relatorios["Relatórios"]) {
                      relatorios["Relatórios"] = [
                        {
                          text: m.fs_titulo,
                          fs_vue: m.fs_vue,
                          fs_nivel: m.fs_nivel,
                          fs_relatorio: m.fs_relatorio,
                          click: () =>
                            this.showHtml(
                              m.fs_vue,
                              m.fs_titulo,
                              m.fs_relatorio,
                              m.fs_http
                            ),
                        },
                      ];
                    } else {
                      relatorios["Relatórios"].push({
                        text: m.fs_titulo,
                        fs_vue: m.fs_vue,
                        fs_nivel: m.fs_nivel,
                        fs_relatorio: m.fs_relatorio,
                        click: () =>
                          this.showHtml(
                            m.fs_vue,
                            m.fs_titulo,
                            m.fs_relatorio,
                            m.fs_http
                          ),
                      });
                    }
                  }
                }
                //console.log(relatorios["Relatórios"]);
                //              this.$store.commit("setRelatorios", relatorios["Relatórios"]);
                this.$store.commit("setRelatorios", relatorios["Relatórios"]);

                //console.log("dados menu reduce");
                //console.log(this.dadosmenu.length);

                if (this.dadosmenu.length > 0) {
                  this.paginas = this.dadosmenu.reduce(function (novo, item) {
                    if (!itens[item.fs_pagina + item.fs_nivel]) {
                      console.log("fs_vue ", item.fs_vue);
                      itens[item.fs_pagina + item.fs_nivel] = {
                        pagina: item.fs_pagina,
                        nivel: item.fs_nivel,
                        funcao: () =>
                          this.showHtml(
                            item.fs_vue,
                            item.fs_titulo,
                            item.fs_relatorio,
                            item.fs_http
                          ),
                      };
                    }
                    return itens;
                  });
                  //console.log("object keys");
                  this.paginas = Object.keys(this.paginas).map((key) => {
                    return this.paginas[key];
                  });
                }

                var pagina;
                var nivel;
                var dadosmenu = this.dadosmenu;
                var itensmenu = [];
                var wpagina = "";
                var wnivel = "";

                // console.log("montando as paginas");
                // console.log(this.paginas);

                if (this.paginas !== null) {
                  //console.log("montando dentro das paginas");
                  //console.log(this.paginas.length);

                  for (let index = 0; index < this.paginas.length; index++) {
                    pagina = this.paginas[index].pagina;
                    nivel = this.paginas[index].nivel;

                    //   console.log(pagina + " " + nivel);
                    if (!itensmenu[pagina + nivel]) {
                      itensmenu[pagina + nivel] = {
                        pagina: pagina,
                        nivel: nivel,
                        funcao: this.paginas[index].funcao,
                        itenssub: dadosmenu.filter(
                          (i) =>
                            i.fs_pagina === pagina &&
                            i.fs_nivel === nivel &&
                            i.fs_nivel !== i.fs_pagina
                        ),
                        itens: dadosmenu.filter(
                          (i) =>
                            i.fs_pagina === pagina &&
                            i.fs_nivel === nivel &&
                            i.fs_nivel === i.fs_pagina
                        ),
                      };
                    } else {
                      itensmenu[pagina + nivel].push({
                        pagina: pagina,
                        nivel: nivel,
                        funcao: this.paginas[index].funcao,
                        itenssub: dadosmenu.filter(
                          (i) =>
                            i.fs_pagina === pagina &&
                            i.fs_nivel === nivel &&
                            i.fs_nivel !== i.fs_pagina
                        ),
                        itens: dadosmenu.filter(
                          (i) =>
                            i.fs_pagina === pagina &&
                            i.fs_nivel === nivel &&
                            i.fs_nivel === i.fs_pagina
                        ),
                      });
                    }
                  }
                }

                this.menu = itensmenu;

                this.menu = Object.keys(this.menu).map((key) => {
                  return this.menu[key];
                });

                itens = [];
                var itenssub = [];
                pagina = "";
                this.menu_geral = [];

                itens = [];
                itenssub = [];
                pagina = "";
                var idxrel = 0;
                this.menu.forEach((element) => {
                  if (pagina !== element.pagina) {
                    if (pagina === "") {
                      pagina = element.pagina;
                    } else {
                      if (pagina === "RRRelatórios" && idxrel === 0) {
                        idxrel = 1;
                        /*this.menu_geral.push({
                      menu_width: 250,
                      text: pagina,
                      menu: itens,
                      click: () => this.showHtml("relatorios", "Relatórios", ""),
                    });*/
                      } else {
                        this.menu_geral.push({
                          menu_width: 250,
                          text: pagina,
                          menu: itens,
                        });
                      }
                      pagina = element.pagina;
                      itens = [];
                      itenssub = [];
                    }
                  }

                  if (element.pagina === element.nivel) {
                    element.itens.forEach((i) => {
                      itens.push({
                        text: i.fs_titulo,
                        click: () =>
                          this.showHtml(
                            i.fs_vue,
                            i.fs_titulo,
                            i.fs_relatorio,
                            i.fs_http
                          ),
                      });
                    });
                  } else {
                    itenssub = [];
                    element.itenssub.forEach((i) => {
                      itenssub.push({
                        text: i.fs_titulo,
                        click: () =>
                          this.showHtml(
                            i.fs_vue,
                            i.fs_titulo,
                            i.fs_relatorio,
                            i.fs_http
                          ),
                      });
                    });

                    itens.push({
                      menu_width: 250,
                      text: element.nivel,
                      menu: itenssub,
                    });
                  }
                });

                this.menu_geral.push({
                  menu_height: 600,
                  text: pagina,
                  menu: itens,
                });

                this.menu_geral = Object.keys(this.menu_geral).map((key) => {
                  return this.menu_geral[key];
                });
                this.carrega_foto_perfil();

                this.overlay = false;
                this.$store.commit("setMenu", this.menu_geral);
                this.$store.commit("setTitulo", `${this.dashboard}`);
                //this.$router.push(`/${this.dashboard}`);

                http
                  .send("", {
                    proc: `[FX jaguar cadastros usuarios] 'chk_solicitacoes',null,null,${this.$store.state.contexto.BD[0].usr_id}`,
                  })
                  .then((res) => {
                    if (parseInt(res.dados.data[0].qtde_solicitacoes) > 0) {
                      this.show_msg_sol = true;
                      this.dashboard = "respondersolicitacao";
                    } else {
                      this.$router.push({ name: `${this.dashboard}` });
                    }
                  });
              } catch (e) {}
            }); // final busca niveis
        }); // final favoritos
    },
  },
};
</script>

<style></style>
